import React, { useState, useEffect, useReducer, useContext } from 'react';
// import logo from './logo.svg';

import '../App.css';
import QuestionSubmissionPage from './QuestionSubmissionPage';
import QuestionsListPage from './QuestionsListPage';
import QuestionLinkPage from './QuestionLinkPage';
import LinkInfoPage from './LinkInfoPage';
import SessionPage from './SessionPage';
import EditPage from './EditPage';

import { MainContext, PAGES } from './Contexts';
import { FirebaseConfig } from '../FirebaseConfig';

//! Review TODO
//! Values in key-value pair need to be different
// https://react-redux.js.org/api/hooks

// MAIN APPLICATION FUNCTIONAL COMPONENT (APP)

// TODO Get key-value pairs from object onto the questions links page and the links info page
// TODO checklist for questions on the links info page, remove array items/ cross out if an X is clicked e.g.


const MainAppSearchPolygon = (props) => {

  // action is a variable of type object created each time

  // action{ 
  // type: 'string'
  // different stuff that does something, 
  // the relevant ACTION taken from the LOCAL code scope (or imported)
  // }

  // EXAMPLES (ACTION can be ANYTHING)
  //   {
  //     type: 'next-page',
  //     nextPageState: PAGES.ENTER_LINKS
  //   }

  //   {
  //     type: 'submit-related-phrases',
  //     relatedKeyPhrases: relatedKeyPhrases any !!
  //   }


  // action.(X) notation which is a value (in key-value pair), in this code refers to it's own key:value pair
  //  I.E. the VALUE in the REDUCER maps to a VALUE in the local code DISPATCH

  // Although keyPhrase and questionsForKeyPhrase are not fully used or updated; they may be important for initial state (for QuestionsListPage dispatch)

  //TODO https://www.epochconverter.com/
  // 1 hour= 3600 unix time  
  // 2 Hour= 7200
  // plus an minus the current time


  const initialState = {
    nextPageState: PAGES.ENTER_QUESTIONS,
    submittedQuestionKeyPhrases: [],
    relatedKeyPhrases: [],
    didSubmitYet: false,
    keyword: "",
    saveSharedSessionId: "",
    keyPhrase: "",
    questionsForKeyPhrase: [],
    keyPhraseToQuestionsList: {},
    sessionId: "",
    updateRadarChart: 0,

    // 1800 test 30mins
    // 3600 test 1hr
    // 7200 test 2hrs
    // 14400 test 2hrs
    // 86400 test 24hrs / 1day
    // 172800 test 2days
    // 604800 test 7days
    // 2592000 test 30days

    rangeSessionTime: 7200,
    sessionTime: 0,

  }

  // ! Explanation - Session Id and updating state
  // Stores the session Id as props
  // Props is passed to the  QuestionSubmissionPage component
  // As this component is wrapped within maincontext.provider within it you are able to use mainStateDispatch
  // From then you can update the state of context
  // Code in QuestionSubmissionPage


  // TODO if else statement for edit 
  // TODO props.match.path === /session/edit

  let sessionId

  let isEditRoute = false

  if (props.match.path === "/session/:sessionId/:keyword" && props.match.params.keyword === "edit") {
    isEditRoute = true
    sessionId = props.match.params.sessionId;

  } else if (
    props.match.path === "/session/:sessionId" ||
    props.match.path === "/session/:sessionId/:keyword" ||
    props.match.path === "/session/:sessionId/:keyword/:custom"
  ) {
    sessionId = props.match.params.sessionId;
  }

  // The routes /session/:sessionId/:keyword and /session/:sessionId/:keyword/:custom
  // no longer used with the plygn.link short URL implementation; although :keyword === edit is used
  // Although you may have shared those links before so leave the code in to keep them enabled

  //Our different DISPATCH cases for useReducer Hook (given via mainContext)
  //  From the reducer we RETURN the new STATE of our mainState OBJECT
  const reducer = (state, action) => {
    console.log("Action", action)
    switch (action.type) {
      case 'next-page':
        return Object.assign({}, state, {
          nextPageState: action.nextPageState
        })
      case 'submit-question':
        return Object.assign({}, state, {
          submittedQuestionKeyPhrases: action.submittedQuestionKeyPhrases
        })
      case 'submit-related-phrases':
        return Object.assign({}, state, {
          relatedKeyPhrases: action.relatedKeyPhrases
        })
      case 'did-submit-yet':
        return Object.assign({}, state, {
          didSubmitYet: action.didSubmitYet
        })
      case 'keyword':
        return Object.assign({}, state, {
          keyword: action.keyword
        })
      case 'save-shared-session-Id':
        return Object.assign({}, state, {
          saveSharedSessionId: action.saveSharedSessionId
        })

      case 'session-Id':
        return Object.assign({}, state, {
          sessionId: action.sessionId
        })
      case 'update-array-chart':
        return Object.assign({}, state, {
          updateRadarChart: action.updateRadarChart
        })


      // ! NEW SESSION INFO SAVED INFO
      // ! CODE  CURRENTLY NOT IMPLEMENTED
      // TODO TO have a session configuration page so that you can choose the time range and e.g. location (by real location and/or by URL location)

      // case 'range-session-time':
      //   return Object.assign({}, state, {
      //     rangeSessionTime: action.rangeSessionTime
      //   })

      case 'session-time':
        return Object.assign({}, state, {
          sessionTime: action.sessionTime
        })


      case 'phrase-to-question-list':
        // Here we are modifying the initial/ current state of keyPhraseToQuestionsList instead of completely changing the value (in the other examples)
        // i.e. adding a new KEY-VALUE pair to the keyPhraseToQuestionsList object
        let newKeyPhraseToQuestionsList = state.keyPhraseToQuestionsList;
        newKeyPhraseToQuestionsList[action.keyPhrase] = action.questionsForKeyPhrase;

        // Here we replace the value of keyPhraseToQuestionsList with the newKeyPhraseToQuestionsList (i.e. after it is modified)
        // In the other examples we MODIFTY in the LOCAL code scopes
        return Object.assign({}, state, {
          keyPhraseToQuestionsList: newKeyPhraseToQuestionsList
        });

      //  modify question list array / for that key phrase
      case 'remove-question':
        let filteredKeyPhraseToQuestionsList = state.keyPhraseToQuestionsList;

        // specific key-value pair from the QuestionsList object
        const oldQuestionsList = filteredKeyPhraseToQuestionsList[action.keyword];

        filteredKeyPhraseToQuestionsList[action.keyword] = oldQuestionsList.filter(item => item !== action.questionToRemove);

        // changing the specific key-value pair in the QuestionsList object 
        // which is part of the whole state that holds everything in context
        return Object.assign({}, state, {
          keyPhraseToQuestionsList: filteredKeyPhraseToQuestionsList
        });
      case 'reset':
        return initialState
      default:
        return state
    }
  }

  const [mainState, dispatch] = useReducer(reducer, initialState)

  // ! Code currently unused
  // (similar to componentWillReceiveProps but for functional component):
  // const isFirstRun = useRef(true);
  // useEffect(() => {
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false;
  //     return;
  //   }
  //   console.log('count changed', props.count);
  //   dispatch({ type: 'next-page', nextPageState: PAGES.SESSION_PAGE });
  // }, [props.count]);

  console.log("mainState props.match", props.match)
  console.log("mainState", mainState)
  console.log("mainState.nextPageState", mainState.nextPageState)

  // In our initial state (context) nextPageState: PAGES.ENTER_QUESTIONS hence this component loads first, hence we pass variables as props in that component, AND from these components we can SAVE in CONTEXT

  function renderSwitch() {
    FirebaseConfig.getFirebaseAnalytics().logEvent('page_render', { page_name: mainState.nextPageState });
    switch (mainState.nextPageState) {
      case PAGES.ENTER_QUESTIONS:
        return <QuestionSubmissionPage sessionId={sessionId} isEditRoute={isEditRoute} />;
      case PAGES.VIEW_QUESTIONS:
        return <QuestionsListPage />;
      case PAGES.ENTER_LINKS:
        return <QuestionLinkPage />;
      case PAGES.VIEW_RESULTS:
        return <LinkInfoPage />;
      case PAGES.SESSION_PAGE:
        return <SessionPage />;
      case PAGES.EDIT_PAGE:
        return <EditPage />;

      default:
        return 'foo';
    }
  }


  return (
    <MainContext.Provider
      value={{ mainState: mainState, mainStateDispatch: dispatch }}
    >

      <div className="App">
        <h1 className="Main-title" >Search Polyg♻️n</h1>
        <h4 className="Live-demo" style={{ backgroundColor: "#ffe4c482", color: "crimson" }}> BETA - Live Demo  🚀</h4>

        <header className="App-header">
          {renderSwitch()}
        </header>
      </div>
    </MainContext.Provider>
  )

}

export default MainAppSearchPolygon;