import firebase from 'firebase';
import 'firebase/database';

var DATABASE = null;

const SHOULD_USE_DEV = !window.location.hostname.includes("searchpolygon.com");

export class FirebaseConfig {

  static initFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyD-CdpmpV6QLTz68--wdO6lwcHrL6WURyo",
      authDomain: "search-polygon.firebaseapp.com",
      databaseURL: "https://search-polygon.firebaseio.com",
      projectId: "search-polygon",
      storageBucket: "search-polygon.appspot.com",
      messagingSenderId: "145639303529",
      appId: "1:145639303529:web:a461ebc3f1a5a844aaf5f8",
      measurementId: "G-TM0LT6JLGD"
    };
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    return firebaseApp;
  }

  static questionBankCollName = SHOULD_USE_DEV ? "dev-question-bank" : "question-bank";
  static keywordQuestionsBankCollName = SHOULD_USE_DEV ? "dev-keyword-questions-bank" : "keyword-questions-bank";
  static sessionUrlsCollName = SHOULD_USE_DEV ? "dev-session-urls" : "session-urls";
  static urlShortenerCollName = SHOULD_USE_DEV ? "dev-url-shortener" : "url-shortener";

  static getFirebaseAnalytics() {
    // const firebaseApp = FirebaseConfig.initFirebase();
    const firebaseAnalytics = firebase.analytics();
    return firebaseAnalytics;
  }

  static getFirebaseDatabase() {
    // const firebaseApp = FirebaseConfig.initFirebase();
    const db = firebase.firestore();
    return db;
  }

  static getFirebaseCollection(coll) {
    if (!DATABASE) {
      const db = FirebaseConfig.getFirebaseDatabase();
      DATABASE = db;
    }
    return DATABASE.collection(coll || FirebaseConfig.questionBankCollName);
  }
}
