import React, { Component, useState, useEffect, useContext } from 'react';
import '../App.css';
import { FirebaseConfig } from '../FirebaseConfig';
import { MainContext, PAGES } from './Contexts';

import { RadarChart } from 'react-vis'
// import RadarChart from 'radar-chart'
// import CircularGridLines from 'plot/circular-grid-lines';

// import firebase from 'firebase';


//! LINK LIST COMPONENT 2) Component

const LinkListItem = (props) => {

  // The initial state of the reactionCount is from props
  // The props is from stateData, which is from the information from Firebase
  // reactionCount added to the data information

  const [reactionCountData, updateReactionCountData] = useState({
    reactionCount: props.data.reactionCount
  });

  const [userReactedData, updateUserReactedData] = useState({
    userReacted: false
  });

  const storageKey = 'storedReactedInfo';

  // When first rendering the component we need to check the local storage memory to see if
  // user reacted is true of false for that specific link i.e. have the reacted or not
  // linked stored by keywords and an array of links

  // storedReactedInfo = {
  //   keyWord: [Link, Link, Link],
  //   KeyWord: [Link, Link, Link]
  //   }

  // props.keyword = keyword information from mainContext
  // if the links exist and includes link - userReacted is true
  // When the page first loads

  // ! Where you would put the code for – the user who created the session can upvote as many times as the want; but it would create a ratio of the upvotes. For the polygon graph
  // ! So userReacted = true, when the user exits the session; if the user created the session (possible implementation)

  useEffect(() => {
    const currentData = localStorage.getItem(storageKey);
    let reactedInfo = {};
    if (currentData) {
      reactedInfo = JSON.parse(currentData);
    }
    if (reactedInfo[props.keyword] && reactedInfo[props.keyword].includes(props.data.link)) {
      updateUserReactedData({ userReacted: true });
    }

  }, [])


  function saveUserReactionInLocalStorage() {
    const currentData = localStorage.getItem(storageKey);
    let reactedInfo = {};
    if (currentData) {
      reactedInfo = JSON.parse(currentData);
    }

    if (reactedInfo[props.keyword]) {
      if (!reactedInfo[props.keyword].includes(props.data.link)) {
        reactedInfo[props.keyword].push(props.data.link);
      }
    } else {
      reactedInfo[props.keyword] = [props.data.link];
    }

    localStorage.setItem(storageKey, JSON.stringify(reactedInfo));
  }

  // Every time reaction count is modified
  // SaveUserReactionInLocalStorage is run and the link is saved in local storage

  // ! Here we get the reaction count for the links and update it (linkData)
  // ! Look in the database for the correct data strucure/ get the information you want

  const mainContext = useContext(MainContext);

  async function modifyReactCount(num) {
    updateReactionCountData({ reactionCount: reactionCountData.reactionCount + num })
    updateUserReactedData({ userReacted: true })

    // !FOR TESTING PURPOSES ONLY  COMMENT OUT userReacted

    const newLinksData = props.allLinksData;
    newLinksData[props.index]['reactionCount'] += num;

    // docRef is updated with the new links information
    // reactionCount is updated/ added to the links information
    await props.docRef.update({
      links: newLinksData
    });

    saveUserReactionInLocalStorage();

    mainContext.mainStateDispatch({ type: 'update-array-chart', updateRadarChart: mainContext.mainState.updateRadarChart + 1 })
  }

  console.log("reactionCountData.reactionCount", reactionCountData.reactionCount)

  // userReactedData when true (reacted) turns the button to disabled

  return (<tr>
    <td className="Wrapping-tabletext">
      <div className="Padding-tablelinks" ><a className="Link-list" href={props.data.link} target="_blank" >{props.data.link.substr(0, 100)}</a> </div></td>
    <td className="Reaction-buttons">
      <span className="Padding-reactionbutton" >{reactionCountData.reactionCount} </span>
      <button className="Up-react-buttons-style" onClick={() => modifyReactCount(1)} disabled={userReactedData.userReacted}>⇧</button>
      <button className="Down-react-buttons-style" onClick={() => modifyReactCount(-1)} disabled={userReactedData.userReacted}>⇩</button>
    </td>
  </tr>)

}




//! LINK INFO COMPONENT 1) Main Component

const LinkInfoPage = (props) => {

  const mainContext = useContext(MainContext);

  // !Doc.ref not defined here but updated with other data in state

  const [stateData, updateStateData] = useState({
    linkData: [],
    questionData: [],
  })


  // Getting data from Firebase and storing it as stateData

  useEffect(() => {
    _getKeywordInfo().then(docLinksArray => {
      const dbDoc = docLinksArray[0]
      const filteredLinks = docLinksArray[1]
      if (dbDoc) {
        console.log("dbDoc.data().links", filteredLinks)

        updateStateData({
          docRef: dbDoc.ref,
          linkData: filteredLinks,
          questionData: dbDoc.data().questions
        });
      }
    })
  }, [mainContext.mainState.updateRadarChart])


  // Getting the documents from firebase which have the same keyword
  // documents are pushed to the empty array
  // promises return an array of querySnapshots

  // TODO if from SessionPage use the sessionKeyword otherwise use keyword. Could have a parameter in state which is a boolean that changes depending on which page that they had been on previously. Which is activated when the change pages. 

  // TODO also to pass the TIME in as a parameter to create the boundary ranges. Also to calculate a time range

  async function _getKeywordInfo() {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
    const promises = [];

    promises.push(coll.where("keyword", "==", mainContext.mainState.keyword).get());
    const querySnapshots = await Promise.all(promises);

    console.log("querySnapshots", querySnapshots)

    // TODO- Allow filtering by TIME and LOCATION and KEYWORD PARAMETERS

    // if not a keyword, in the database, document would be undefined
    // returns document
    // document = dbDoc for the function _getKeywordInfo which is used in the useEffect
    // to then update the state of stateData (above)

    if (querySnapshots) {
      const document = querySnapshots[0].docs[0];
      console.log("querySnapshots[0].docs[0]", querySnapshots[0].docs[0])

      // Filter links in the document to the links that are in the time range.

      //! Error message uncaught promise -if document.data().links is undefined
      // ! Doesn't break the code in production but does in development
      // TODO CORRECT THIS

      // SessionTime is stored in state from the sessionPage where the information is retrieved from the database


      let filteredLinks = document.data().links
      if (mainContext.mainState.sessionTime != 0) {

        const sessionRangeStart = mainContext.mainState.sessionTime - mainContext.mainState.rangeSessionTime;

        const sessionRangeEnd = mainContext.mainState.sessionTime + mainContext.mainState.rangeSessionTime;
        console.log("document.data().links BEFORE", document.data().links)

        // timeIsInRange is a conditional that is passed to .some to filter

        const timeIsInRange = (e) => e.seconds >= sessionRangeStart && e.seconds <= sessionRangeEnd;

        const linkIsInSession = element => element.timeAdded === undefined || element.timeAdded.some(timeIsInRange);

        // document.data().links = document.data().links.filter(linkIsInSession)
        // console.log("document.data().links AFTER", document.data().links)

        filteredLinks = document.data().links.filter(linkIsInSession)
        console.log("document.data().links AFTER", filteredLinks)

      }


      return [document, filteredLinks];

    }
    console.log("LinkInfoPage - did not return")
  }

  // Interesting that although when you go back after you have come from the sessions page; you go back to the sessions pages instead of back to the ENTER_QUESTIONS PAGE
  // From console.log of main.state it goes to the ENTER_QUESTIONS page then later changes to the SESSIONS PAGE via the router information

  async function _handleBack(e) {
    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_QUESTIONS });
  }

  // As onClick is used you can pass the element  content that was clicked to another function
  function _handleRemoveQuestion(questionToRemove) {
    mainContext.mainStateDispatch({ type: 'remove-question', keyword: mainContext.mainState.keyword, questionToRemove: questionToRemove });
  }


  // const DATA = stateData.linkData.map((d, i) => ({ [d["link"].replace("https://", "").replace(".", "")]: d["reactionCount"] }));

  const getHostname = (url) => {
    // use URL constructor and return hostname
    return new URL(url).hostname;
  }

  // ! REFERENCE  https://github.com/uber/react-vis/blob/premodern/docs/radar-chart.md

  // !  PLUS 5 so that the Shape looks like a shape by default without any up or down votes

  let dataItem = {}
  for (const data of stateData.linkData) {
    dataItem[
      (getHostname(data['link'])).replace("www.", "").replace(".com", "").replace(".co.uk", "").replace(".org", "").replace(".net", "").replace(".gov", "")
    ] = data['reactionCount'] + 5
  }
  const DATA = [dataItem];
  // [
  //   {
  //     "https://bbc.com": 2
  //   }
  // ];


  const DOMAIN = stateData.linkData.map((d, i) => ({
    "name":
      (getHostname(d["link"])).replace("www.", "").replace(".com", "").replace(".co.uk", "").replace(".org", "").replace(".net", "").replace(".gov", "")
    , "domain": [0, 20]
  }));

  console.log("stateData.linkData", stateData.linkData, { DATA }, { DOMAIN })


  // The data from stateData is mapped over
  // as well as other non mapped information is passed on as props too to LinkListItem component
  // ALL (const Linklistitems) is returned and rendered on the page. items with an S! 

  const linkListItems = stateData.linkData.map((d, i) => <LinkListItem key={i} index={i} docRef={stateData.docRef} data={d} allLinksData={stateData.linkData} keyword={mainContext.mainState.keyword} />);

  // question data is retrieved from mainContext
  // keyword data is also retrieved from mainContext
  // mainContext.mainState repeated twice, essentially a shortcut for an if statement i.e. if the keyword list exist/ is true

  return (
    <div className="Background-link-info " style={{ backgroundColor: "midnightblue" }} >
      <h3>Keyword: {mainContext.mainState.keyword}</h3>
      <ul>
        {mainContext.mainState.keyPhraseToQuestionsList[mainContext.mainState.keyword] && mainContext.mainState.keyPhraseToQuestionsList[mainContext.mainState.keyword].map((question) =>
          <li className="Questions-link-info" key={question}>
            {/* Extra spans needed for ratios of grid template columns */}
            <span></span>
            <small className="Grid-child" >{question}</small>
            <span className="Grid-child checklist" onClick={(_) => _handleRemoveQuestion(question)} type="button"
            >✅</span>
            <span></span>
          </li>
        )}
      </ul>
      <table style={{ width: "100%", margin: "auto" }}>
        <colgroup>
          <col style={{ width: "55%" }} />
          <col style={{ width: "45%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Links</th>
            <th> <span className="React-text" > React
              </span></th>
          </tr>
        </thead>
        <tbody>
          {linkListItems}
        </tbody>
      </table>
      <button className="Back-page-navigation-button" onClick={_handleBack}>Back</button>

      { window.screen.width < 800 && <div className="Radar-chart">
        <RadarChart
          animation
          data={DATA}
          domains={DOMAIN}
          style={{
            polygons: {
              fillOpacity: 0,
              strokeWidth: 3
            },
            axes: {
              text: {
                opacity: 1
              }
            },
            labels: {
              textAnchor: 'middle',
              fontSize: 22,
              // defaultFontColor: '#ffffff',
            }
          }}
          margin={{
            left: 90,
            top: 40,
            bottom: 40,
            right: 80
          }}
          tickFormat={t => ''}
          width={410}
          height={340}
        >
          {/* <CircularGridLines
            tickValues={[...new Array(10)].map((v, i) => i / 10 - 1)}
          /> */}
        </RadarChart>
      </div>}

      { window.screen.width >= 800 && <div className="Radar-chart">
        <RadarChart
          animation
          data={DATA}
          domains={DOMAIN}
          style={{
            polygons: {
              fillOpacity: 0,
              strokeWidth: 3
            },
            axes: {
              text: {
                opacity: 1
              }
            },
            labels: {
              textAnchor: 'middle',
              fontSize: 24,
              // defaultFontColor: '#ffffff',
            }
          }}
          margin={{
            left: 160,
            top: 40,
            bottom: 40,
            right: 160
          }}
          tickFormat={t => ''}
          width={600}
          height={360}
        >
          {/* <CircularGridLines
            tickValues={[...new Array(10)].map((v, i) => i / 10 - 1)}
          /> */}
        </RadarChart>

      </div>}

    </div>
  )
}

export default LinkInfoPage;
