import React, { useState } from 'react'
import '../App.css'
import { Link } from 'react-router-dom'

const AboutPage = () => {

  return <div className="About-fullpage" style={{ backgroundColor: "#20202ce8" }}> <div style={{ borderStyle: "groove", padding: 15 }}>
    <header className="App-header App">

      <h2 style={{ marginBottom: 10, marginTop: 30, color: "#whitesmoke", fontWeight: 200 }}> Introducing Search Polyg♻️n</h2>
      <h2 style={{ margin: 10, color: "aquamarine", letterSpacing: 2 }}> A collaborative
      <span style={{ textDecoration: "underline" }} >  search </span>
      tool</h2>
      <h4 style={{ marginTop: 40, lineHeight: 2, color: "#ed0c0cb0" }}>
        <span style={{ letterSpacing: 4 }} className="Colour-animation"> Connect </span>
        multiple sources, <br />
        <span style={{ letterSpacing: 2 }} className="Colour-animation"> Link </span>
        your knowledge, <br />
        <span style={{ letterSpacing: 2 }} className="Colour-animation"> Shape </span>

      your search!</h4>
      <Link to={'/'}>
        <h3 style={{ borderStyle: "outset", borderColor: "red", borderWidth: 2, borderRadius: 5, color: "white", textDecoration: "none", margin: 30 }}> Start </h3>
      </Link>
      <p>
        <ul className="About-page-benefits" >
          <li>Team up & reduce repetition</li>
          <li>Pool similar questions</li>
          <li>Find relevant resources by key words</li>
          <li>Vote the most valuable links</li> <br />
          <li>Share your research with a link 🌐 </li>
        </ul>
      </p>

    </header>
  </div>
  </div>

}

export default AboutPage