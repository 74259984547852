import React, { useReducer } from 'react'

export const MainContext = React.createContext()

export const PAGES = {
  ENTER_QUESTIONS: 'enter_questions',
  VIEW_QUESTIONS: 'view_questions',
  ENTER_LINKS: 'enter_links',
  VIEW_RESULTS: 'view_result',
  SESSION_PAGE: 'session_page',
  EDIT_PAGE: 'edit_page',
}