import React, { useState, useContext, useEffect } from 'react';
import '../App.css';
import { FirebaseConfig } from '../FirebaseConfig';
import firebase from 'firebase';
import { MainContext, PAGES } from './Contexts';


//! LINKS LIST COMPONENT (INNER)

function LinksList(props) {
  const links = props.links;
  console.log('links', links);
  const listItems = links.map((q, i) => <div> <li className="Links-styling" key={i}>{q}</li></div>);
  return (
    <ul>{listItems}</ul>
  );
}

//! LINKS RESPONSE COMPONENT (MIDDLE)

const LinkResponse = (props) => {
  const [linksData, updateLinksData] = useState({
    links: []
  })



  /*   updateLinksData which updates the state;  wraps around a FUNCTION which pushes the web links to an ARRAY (called "links")
    The array is a value (of a key - value pair) inside an OBJECT called "props.phraseLinks"
    The object has a KEY called "props.keyPhrase" and the value of the "links" array
   */

  console.log("LinkResponse - props.phraseLinks", props)

  useEffect(() => {
    props.phraseLinks[props.keyPhrase] = [];
  }, [])


  // todo more validation required with the links; e.g. no spaces and using a url validator

  /* initialising the OBJECT called "props.phraseLinks"
    with an KEY of "props.keyPhrase" and the value of an empty array, for the submitted links
   */

  function _handleKeyDownLinks(e) {
    if (e.key === 'Enter') {
      if (e.target.value.startsWith("http://") || e.target.value.startsWith("https://")) {
        const newLink = e.target.value.trim();
        props.phraseLinks[props.keyPhrase].push(newLink);
        updateLinksData({
          links: [...linksData.links, newLink]
        });
        console.log("props.phraseLinks 1", props.phraseLinks)
        props.phraseLinksUpdated(props.phraseLinks);
        e.target.value = null;
      } else {
        alert("This field must be a URL");
      }
    }
  }

  function _handleSearchEngine(question) {


    const editedQuestion = question.replace(/ /g, '+')

    const url = `https://www.google.com/search?q=${editedQuestion}`

    console.log(url)

    let win = window.open(url, '_blank');

  }




  console.log("props.keyPhraseQuestions", props.keyPhraseQuestions);

  // mapping over the array of questions; from the keyPhraseToQuestionsList object

  return (
    <li>
      <p className="keyword-bottomspacing">{props.keyPhrase}</p>
      <LinksList links={linksData.links} />
      {props.keyPhraseQuestions && props.keyPhraseQuestions.length > 0 && <hr style={{ marginTop: 4 }} />}
      <ul>
        {props.keyPhraseQuestions && props.keyPhraseQuestions.map((question) =>
          <li className="Grid-container" key={question}>
            <span></span>
            <small className="Question-styling" >{question}</small>
            <button className="Search-engine" onClick={(_) => _handleSearchEngine(question)} > 🌐 </button>
            <span></span>
          </li>
        )}
      </ul>
      {props.keyPhraseQuestions && props.keyPhraseQuestions.length > 0 && <hr
        style={{ marginTop: 22 }}
      />}
      {/* <LinksList links={props.phraseLinks[props.keyPhrase]} /> */}
      <input className="Text-input-questionlistpage"
        placeholder="Enter/paste a link..." type="url" pattern="https?://.*" onKeyDown={_handleKeyDownLinks} autoFocus />
    </li>
  )

}


//! QUESTION LINK PAGE COMPONENT (OUTER; calls/ returns other components and passes properties)

const QuestionLinkPage = () => {

  const mainContext = useContext(MainContext);


  const [phraseLinksData, updatePhraseLinksData] = useState({
    phraseLinks: {}
  });

  // phraseLinksData.phraseLinks is an OBJECT - with 'keyword' as [keys] and 'links' as [values]

  const [linksExistData, updateLinksExistData] = useState({
    linksExist: false
  });


  //! Here is where it should add the link to the same keyword

  async function _saveLinkInDb(keyword, link) {
    const currentTimeFirebase = new firebase.firestore.Timestamp.fromDate(new Date());

    // linkToAddToDb is added as a value (key: value pair) when adding the collection (coll.add)
    // and when pushing new links information to an existing keyword document
    const linkToAddToDb = {
      'link': link,
      'reactionCount': 0,
      'timeAdded': [currentTimeFirebase],
    };
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
    const querySnapshot = await coll.where("keyword", "==", keyword).get();
    console.log("querySnapshot", querySnapshot);

    // querySnapshot.docs is each of the keyword documents which contains links etc.
    if (querySnapshot.docs.length !== 0) {
      console.log("Adding to existing document");
      const doc = querySnapshot.docs[0];
      console.log("doc", doc);
      const newLinksData = doc.data().links;
      console.log("newLinksData", newLinksData);

      // Adding time to the links
      // Find the links that are the same from the newLinksData
      // If there is an existing link push it to the array;
      // else create a new array for the times; which is associated with the existing link
      // After update the existing links for the database

      const existingLink = newLinksData.find(element => element['link'] === link);
      if (existingLink) {
        if (existingLink['timeAdded']) {
          existingLink['timeAdded'].push(currentTimeFirebase);
        } else {
          existingLink['timeAdded'] = [currentTimeFirebase];
        }
        doc.ref.update({
          links: newLinksData
        });
      } else {
        newLinksData.push(linkToAddToDb);
        doc.ref.update({
          links: newLinksData
        });
      }
      // If the keyword doesn't exist a new keyword document is created
    } else {
      console.log("Adding a new document");
      await coll.add({
        'keyword': keyword,
        'links': [linkToAddToDb]
      });
      const testQuerySnapshot = await coll.where("keyword", "==", keyword).get();
      console.log("New testQuerySnapshot", testQuerySnapshot);
      // A console.log to check if added to database
    }
  }



  async function _handleSubmit(e) {
    const promises = [];

    for (const keyword in phraseLinksData.phraseLinks) {
      for (const link of phraseLinksData.phraseLinks[keyword]) {
        console.log("link", link);
        console.log("phraseLinksData.phraseLinks[keyword]", phraseLinksData.phraseLinks[keyword]);
        await _saveLinkInDb(keyword, link);
      }

    }

    mainContext.mainStateDispatch({ type: 'did-submit-yet', didSubmitYet: true })
    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_QUESTIONS })
      ;
  }

  // async function _handleSkip(e) {
  //   mainContext.mainStateDispatch({ type: 'did-submit-yet', didSubmitYet: false })
  //   mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_QUESTIONS })
  // }

  function _onPhraseLinksUpdates(newLinks) {
    console.log("newLinks 2", newLinks)
    updatePhraseLinksData({
      phraseLinks: newLinks
    })
    updateLinksExistData({
      linksExist: !!newLinks,
    });
  }

  return (
    <div>
      <h2>Link your knowledge</h2>
      <h4>Shape your search:</h4>
      {linksExistData.linksExist &&
        <h6 className="Styling-explanation-text"> Enter any number of links as required: < br /> Click Submit below to continue</h6>}
      <ul style={{ listStyleType: "none" }}
      >
        {mainContext.mainState.submittedQuestionKeyPhrases.map((value, index) => {
          return <LinkResponse keyPhrase={value} key={index} phraseLinks={phraseLinksData.phraseLinks} phraseLinksUpdated={_onPhraseLinksUpdates} keyPhraseQuestions={mainContext.mainState.keyPhraseToQuestionsList[value]}
          />;
        })}
      </ul>
      {mainContext.mainState.relatedKeyPhrases.length !== 0 && <h4>Related questions:</h4>}
      <ul style={{ listStyleType: "none" }}>
        {mainContext.mainState.relatedKeyPhrases.map((value, index) => {
          return <LinkResponse keyPhrase={value} key={index} phraseLinks={phraseLinksData.phraseLinks} phraseLinksUpdated={_onPhraseLinksUpdates} />;
        })}
      </ul>
      {linksExistData.linksExist &&
        <button className="Link-page-navigation-button" onClick={_handleSubmit}>Submit</button>
      }
    </div>
  )

}

export default QuestionLinkPage;
