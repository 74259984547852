import React, { useState, useContext, useEffect } from 'react';
import '../App.css';
import { FirebaseConfig } from '../FirebaseConfig';
import { MainContext, PAGES } from './Contexts';

// Both questions: [] and relatedQuestions: [] empty values, required for the initial state

const QuestionsListPage = (props) => {
  const [stateData, updateStateData] = useState({
    questions: [],
    relatedQuestions: []
  })

  const mainContext = useContext(MainContext);

  // dbDocuments returned from _getRealQuestions EQUALS dbDocuments
  useEffect(() => {
    _getRealQuestions(mainContext).then(dbDocuments => {
      const questions = dbDocuments.map(d => d.data().text);

      _getRelatedQuestions(dbDocuments, questions).then(relatedDbQuestions => {
        const relatedQuestions = relatedDbQuestions.map(d => ({ text: d.data().text, keyPhrases: d.data().keyPhrases }));

        updateStateData({
          questions: questions,
          relatedQuestions: relatedQuestions
        });
      });
    });
  }, [])


  // ! START OF _getRealQuestions FUNCTION
  // This function saves the keywords and the questions in Context using dispatch
  // And get the documents that match the keyPhrases to extract the questions and save in state

  async function _getRealQuestions(mainContext) {

    const questionBank = FirebaseConfig.getFirebaseCollection();
    const dbQuestionIds = new Set();
    const dbQuestionTexts = new Set();
    const dbDocuments = [];


    // For Loop completing code for each Key Phrase
    for (const kP of mainContext.mainState.submittedQuestionKeyPhrases) {

      const questionsForKeyPhrase = [];
      const queryToRun = questionBank.where("keyPhrases", "array-contains", kP).orderBy('timestamp', 'desc').limit(3);
      const querySnapshot = await queryToRun.get();

      //  gets the documents from questionBank where the key phrase matches
      querySnapshot.forEach((doc) => {
        if (!dbQuestionIds.has(doc.id) && !dbQuestionTexts.has(doc.data().text)) {
          dbQuestionTexts.add(doc.data().text);
          dbQuestionIds.add(doc.id);
          questionsForKeyPhrase.push(doc.data().text);

          // dbQuestions EQUALS dbDocuments
          dbDocuments.push(doc);
        }
      })

      // key value pairs because we want there to be variable  values
      // could either pass through the whole object OR the keys and values that are to be modified only
      // DISPATCH (action object ) is called multiple times, once for EACH kP (key phrase)
      // key value pairs in DISPATCH only need to match if you are modifying something directly, they are still passed into the action object

      mainContext.mainStateDispatch({
        type: 'phrase-to-question-list',
        keyPhrase: kP,
        questionsForKeyPhrase: questionsForKeyPhrase
      });

    }

    // still required to save all questions docs in an array (side effect); 
    // and as used later to compare related questions
    return dbDocuments;

  }

  // ! END OF _getRealQuestions FUNCTION



  // ! _getRelatedQuestions EXPLANATION
  // doc has already been filtered for the same keyPhrase which match the questions
  //  and gets the (documents) and questions which are not the same as those from _getRealQuestions
  // i.e. another user has questions which have the same keyword, other questions from that same timestamp are retrieved

  // dbDocuments is everything that has the keyphrase
  // timestamp of the document not just one user 

  async function _getRelatedQuestions(dbDocuments, questions) {
    const alreadyGottenQuestionSet = new Set(questions);
    const relatedQuestionTexts = new Set();
    const relatedDbQuestions = [];
    const questionBank = FirebaseConfig.getFirebaseCollection();

    for (const doc of dbDocuments) {
      const querySnapshots = await questionBank
        .where("timestamp", "==", doc.data().timestamp)
        .get();

      querySnapshots.forEach(doc => {
        if (!alreadyGottenQuestionSet.has(doc.data().text) && !relatedQuestionTexts.has(doc.data().text) && relatedDbQuestions.length <= 5) {
          relatedQuestionTexts.add(doc.data().text);
          relatedDbQuestions.push(doc);
        }
      });
    }

    return relatedDbQuestions;
  }


  // ! Function _handleSubmit stores related key phrases in Context

  function _handleSubmit(e) {
    console.log('  stateData.relatedQuestions', stateData.relatedQuestions);
    let relatedKeyPhrases = [];

    //   stateData.relatedQuestions is an array of objects - with keyPhrases array and text

    const tempListOfPhrases = stateData.relatedQuestions.map((q, i) => q.keyPhrases);
    console.log('tempListOfPhrases', tempListOfPhrases);

    for (const tempPhrases of tempListOfPhrases) {
      if (tempPhrases) {
        relatedKeyPhrases.push(...tempPhrases);
      }
    }

    console.log('relatedKeyPhrases', relatedKeyPhrases);
    let uniqueRelatedKeyPhrases = [...new Set(relatedKeyPhrases)];

    // Here we have limited related questions to 3 KeyWords

    uniqueRelatedKeyPhrases = uniqueRelatedKeyPhrases.slice(0, 3);

    mainContext.mainStateDispatch({ type: 'submit-related-phrases', relatedKeyPhrases: uniqueRelatedKeyPhrases });
    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_LINKS });
  }




  const questions = stateData.questions;
  const relatedQuestions = stateData.relatedQuestions;
  let listItems = [];
  let relatedListItems = [];

  if (questions) {
    listItems = questions.map((question, i) => <li className="Question-listspacing" key={i}>{question}</li>);
  }
  if (relatedQuestions) {
    relatedListItems = relatedQuestions.map((question, i) => <li className="Question-listspacing" key={i}>{question.text}</li>);
  }


  return (
    <div>
      <h4>Questions</h4>
      <p>Key phrases: {mainContext.mainState.submittedQuestionKeyPhrases.join(", ")}</p>
      <ul> <span className="Bulletpoint-spacing"> {listItems} </span></ul>

      {relatedListItems.length !== 0 && <h4> Related questions:</h4>}

      {relatedListItems.length !== 0 &&
        <ul> <span className="Bulletpoint-spacing">{relatedListItems} </span> </ul>}

      <button className="Next-page-navigation-button" onClick={_handleSubmit}>Next</button>

    </div>
  );


}

export default QuestionsListPage;
