import React, { useState, useEffect, useContext } from 'react'
import '../App.css'
import { FirebaseConfig } from '../FirebaseConfig';
import { Link } from 'react-router-dom'
import { MainContext, PAGES } from './Contexts';


const EditPage = (props) => {

  const mainContext = useContext(MainContext)

  const sessionId = mainContext.mainState.sessionId

  async function _returnToSessionPage() {
    await _SaveSessionDocument()
    await _saveAllLinks()
    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.SESSION_PAGE })
  }

  // A loop for saving all the keywords and links to the database

  async function _saveAllLinks() {
    for (let [keyword, links] of newKeywordLinksMap.value.entries()) {
      for (const link of links) {
        await _saveLinkInDb(keyword, link)
      }
    }
  }


  async function _saveLinkInDb(keyword, link) {

    // linkToAddToDb is added as a value (key: value pair) when adding the collection (coll.add)
    // and when pushing new links information to an existing keyword document
    const linkToAddToDb = {
      'link': link,
      'reactionCount': 0,
      'timeAdded': [sessionInfo.timestamp],
    };
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
    const querySnapshot = await coll.where("keyword", "==", keyword).get();
    console.log("querySnapshot", querySnapshot);

    // querySnapshot.docs is each of the keyword documents which contains links etc.
    if (querySnapshot.docs.length !== 0) {
      // console.log("Adding to existing document", link);
      const doc = querySnapshot.docs[0];
      const newLinksData = doc.data().links;

      // Adding time to the links
      // Find the links that are the same from the newLinksData
      // If there is an existing link push it to the array;
      // else create a new array for the times; which is associated with the existing link
      // After update the existing links for the database

      const existingLink = newLinksData.find(element => element['link'] === link);
      if (existingLink) {
        if (existingLink['timeAdded']) {
          existingLink['timeAdded'].push(sessionInfo.timestamp);
        } else {
          existingLink['timeAdded'] = [sessionInfo.timestamp];
        }
        await doc.ref.update({
          links: newLinksData
        });
      } else {
        newLinksData.push(linkToAddToDb);
        await doc.ref.update({
          links: newLinksData
        });
      }
      // If the keyword doesn't exist a new keyword document is created
    } else {
      console.log("Adding a new document", link);
      await coll.add({
        'keyword': keyword,
        'links': [linkToAddToDb]
      });
      const testQuerySnapshot = await coll.where("keyword", "==", keyword).get();
      console.log("New testQuerySnapshot", testQuerySnapshot);
      // A console.log to check if added to database
    }
  }


  async function _getSessionInfo() {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);
    const document = (await coll.doc(sessionId).get()).data()
    console.log(document)
    return document
  }

  // sessionInfo is an object
  const [sessionInfo, updateSessionInfo] = useState({
    keywords: [],
    renameTitle: "",
    timestamp: {}
  })

  const [totalKeywordLinksCountMap, updateTotalKeywordLinksCountMap] = useState({
    value: new Map()
  })

  const [newKeywordLinksMap, updateNewKeywordLinksMap] = useState({
    value: new Map()
  })

  const [isSessionEdit, setSessionEdit] = useState(false)



  useEffect(() => {
    async function fetchData() {
      const sessionInfo = await _getSessionInfo()
      updateSessionInfo(sessionInfo)
      if (sessionInfo.keywords.length > 0) {
        setSessionEdit(true)
      }
      await processAllKeywords(sessionInfo.keywords, sessionInfo.timestamp.seconds)
    }
    fetchData();
  }, [])

  function addLink(keyword, link) {
    if (newKeywordLinksMap.value.has(keyword)) {
      const existingLinks = newKeywordLinksMap.value.get(keyword)
      if (existingLinks.includes(link) === true) {
        alert(`Link already added to session keyword: ${keyword}`)
      }
      if (existingLinks.includes(link) === false) {
        newKeywordLinksMap.value.set(keyword, [...existingLinks, link])
        totalKeywordLinksCountMap.value.set(keyword, totalKeywordLinksCountMap.value.get(keyword) + 1)

      }
    } else {
      newKeywordLinksMap.value.set(keyword, [link])
      totalKeywordLinksCountMap.value.set(keyword, totalKeywordLinksCountMap.value.get(keyword) + 1)

    }
    updateNewKeywordLinksMap({ value: newKeywordLinksMap.value })

    console.log('NEW-LINKS-MAP', newKeywordLinksMap)
  }

  function addKeyword(keyword) {

    const keywordLowercase = keyword.toLowerCase()

    if (sessionInfo.keywords.includes(keywordLowercase)) {
      alert(`Keyword "${keywordLowercase}" already exists in session`)
      return;
    }

    updateSessionInfo({
      keywords: [...sessionInfo.keywords, keywordLowercase],
      renameTitle: sessionInfo.renameTitle,
      timestamp: sessionInfo.timestamp,
    })
    totalKeywordLinksCountMap.value.set(keywordLowercase, 0)
    updateTotalKeywordLinksCountMap({ value: totalKeywordLinksCountMap.value })
  }


  async function _SaveSessionDocument() {
    const sessionUrlsColl = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);
    const matchedDocuments = await sessionUrlsColl.doc(sessionId).update({ keywords: sessionInfo.keywords })
  }

  async function processAllKeywords(keywords, timestamp) {
    console.log("** sessionInfo.keywords", keywords)
    for (const keyword of keywords) {
      const linkCount = await _getKeywordInfo(keyword, timestamp);
      console.log("** linkCount", keyword, linkCount)
      totalKeywordLinksCountMap.value.set(keyword, linkCount);
    }
    updateTotalKeywordLinksCountMap({ value: totalKeywordLinksCountMap.value });
    console.log("** totalKeywordLinksCountMap.value", totalKeywordLinksCountMap.value)
  }

  // sessionTime = timestamp = sessionInfo.timestamp
  // this is passed in as a parameter in the _getKeywordInfo function

  async function _getKeywordInfo(keyword, sessionTime) {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
    const promises = [];
    promises.push(coll.where("keyword", "==", keyword).get());
    const querySnapshots = await Promise.all(promises);
    console.log("querySnapshots", querySnapshots)
    let linkCount = 0

    if (querySnapshots) {
      const document = querySnapshots[0].docs[0];
      console.log("querySnapshots[0].docs[0]", querySnapshots[0].docs[0])

      if (document) {
        let filteredLinks = document.data().links
        if (sessionTime != 0) {

          const sessionRangeStart = sessionTime - mainContext.mainState.rangeSessionTime;

          const sessionRangeEnd = sessionTime + mainContext.mainState.rangeSessionTime;
          const timeIsInRange = (e) => e.seconds >= sessionRangeStart && e.seconds <= sessionRangeEnd;
          const linkIsInSession = element => element.timeAdded === undefined || element.timeAdded.some(timeIsInRange);
          filteredLinks = document.data().links.filter(linkIsInSession)
          return filteredLinks.length;
        }
      }

    }
    return linkCount;
  }

  const KeywordListItem = (props) => {

    return (
      <tr>
        <td>
          {props.keyword.charAt(0).toUpperCase() + props.keyword.slice(1)}
        </td>
        <td>
          {totalKeywordLinksCountMap.value.get(props.keyword)}
        </td>
        <AddLinkComponent keyword={props.keyword} />
      </tr>)

  }

  // Located in KeyListItem above; shown on each row (list of items)
  const AddLinkComponent = (props) => {

    const [ShowLinkInput, updateShowLinkInput] = useState(false)

    function _showLinkInput() {
      updateShowLinkInput(true)
    }


    function _linksHandleKeyDown(e) {
      if (e.key === 'Enter') {

        if (e.target.value.startsWith("http://") || e.target.value.startsWith("https://")) {
          const newLink = e.target.value.trim();
          addLink(props.keyword, newLink)
          updateShowLinkInput(false)
          e.target.value = null;

        } else {
          alert("This field must be a URL");
        }
      }
    }



    return (
      <div>
        {!ShowLinkInput && <button onClick={_showLinkInput} className="Add-link-button"> Add Link </button>}

        {ShowLinkInput && <input placeholder="Enter/paste" onBlur={(e) => {
          updateShowLinkInput(false)
        }}
          onKeyDown={_linksHandleKeyDown} autoFocus className="Add-link-button" />}
      </div>)

  }

  // Located in Main Table below
  const AddKeywordComponent = (props) => {


    const [ShowKeywordInput, updateShowKeywordInput] = useState(false)

    function _showKeywordInput() {
      updateShowKeywordInput(true)
    }

    function _keywordHandleKeyDown(e) {
      if (e.key === 'Enter') {
        let trimmedInput = e.target.value.trim()
        if (trimmedInput === "") {
          return
        }

        trimmedInput = ((trimmedInput.split(" "))[0])
        // trimmedInput = trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1)

        addKeyword(trimmedInput)
        updateShowKeywordInput(false)
        e.target.value = null;
      }
    }



    return (
      <div>
        {!ShowKeywordInput && <button onClick={_showKeywordInput} className="Add-keyword-button-style"> Add Keyword </button>}
        {ShowKeywordInput && <input placeholder="Enter keyword ..." onBlur={(e) => {
          updateShowKeywordInput(false)
        }} onKeyDown={_keywordHandleKeyDown} autoFocus className="Add-keyword-button-style" />}
      </div>)

  }



  const keywordListItems = sessionInfo.keywords.map((keyword, i) => <KeywordListItem key={i} index={i} keyword={keyword} />);


  return <div className="submit-question-container App-header" style={{ backgroundColor: "#50041ee6" }} >

    {isSessionEdit &&
      <h1 className="Heading-session-edit">Session Edit</h1>
    }

    {!isSessionEdit &&
      <h1 className="Heading-session-edit">Quick Session Edit ⚡️</h1>
    }



    <button className="Finish-edit-button"> <Link to={`/session/${sessionId}`} onClick={_returnToSessionPage} className="Finish-edit-text"> Finish Edit </Link> </button>


    {/* <table className="Table-edit-page" style={{ width: "100%", margin: "auto" }}> */}


    <table className="Table-edit-page" style={{ width: "100%" }}>
      <colgroup>
        <col style={{ width: "55%" }} />
        <col style={{ width: "45%" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Keywords</th>
          <th> <span className="React-text" > Links
              </span></th>
        </tr>
      </thead>
      <tbody>
        {keywordListItems}
        {/* if it is a session edit then show the component without the arrow */}
        {isSessionEdit &&
          <AddKeywordComponent />
        }


        {/* if it's not a session edit i.e. it is a quick session edit and keywords is less than 1 then show with the arrow animation */}
        {!isSessionEdit && (sessionInfo.keywords.length < 1) &&
          <tr>
            <td>
              <AddKeywordComponent />
            </td>
            <td>
              {<h1 className="Arrow-animation-add-keyword" > &lt;------- </h1>}

            </td>
          </tr>
        }

        {/* if it's not a session edit i.e. it is a quick session edit and keywords is greater than one show without the arrow animation */}

        {!isSessionEdit && (sessionInfo.keywords.length >= 1) &&
          <tr>
            <td>
              <AddKeywordComponent />
            </td>
            <td>

            </td>
          </tr>
        }


      </tbody>
    </table>





  </div>

}


export default EditPage