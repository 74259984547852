import React, { useState, useEffect } from 'react'
import '../App.css'
import { Redirect, Link } from 'react-router-dom'
import { FirebaseConfig } from '../FirebaseConfig';


const UrlShortener = (props) => {

  let indexSessionId
  let keyword

  if (props.match.path === "/:indexSessionId/:keyword") {

    indexSessionId = props.match.params.indexSessionId;
    keyword = props.match.params.keyword;

    console.log("indexSessionId", indexSessionId)
    console.log("keyword", keyword)

  }

  async function _getSessionInfo() {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.urlShortenerCollName);
    const document = (await coll.doc(keyword).get()).data()
    return document
  }

  const [sessionIdObject, updateSessionInfo] = useState({})


  useEffect(() => {
    async function fetchData() {
      const sessionInfo = await _getSessionInfo()
      console.log("HELLO session info", sessionInfo)
      updateSessionInfo(sessionInfo)
    }
    fetchData();
  }, [])




  console.log("UPDATED STATE sessionIdObject", sessionIdObject)
  // console.log("indexSessionId BELOW", indexSessionId)

  const sessionIdArray = sessionIdObject.sessionId

  if (sessionIdArray) {

    console.log("sessionIdArray", sessionIdArray)

    const sessionIdFromDb = sessionIdArray[indexSessionId]
    console.log("UPDATED sessionIdFromDb", sessionIdFromDb)

    return <div>

      <Redirect to={`/session/${sessionIdFromDb}`} />

    </div>

  }
  return <div>

  </div>


}

export default UrlShortener