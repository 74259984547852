import React from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom'

import MainAppSearchPolygon from './components/MainAppSearchPolygon'
import AboutPage from './components/AboutPage'
import UrlShortener from './components/UrlShortener'
import TrendingActivityPage from './components/TrendingActivityPage'


// The components within the component MainAppSearchPolygon can use the variables saved in Context
// including sessionId and rangeSessionTime etc.


const App = () => {
  return <HashRouter>
    <Switch>


      <Route exact path=
        {["/",
          "/session/:sessionId",
          "/session/:sessionId/:keyword",
          "/session/:sessionId/:keyword/:custom"]} component={MainAppSearchPolygon} />

      <Route path="/about" component={AboutPage} />

      <Route path="/:indexSessionId/:keyword" component={UrlShortener} />

      <Route path="/trending" component={TrendingActivityPage} />


    </Switch>

  </HashRouter >

}

export default App

// : type one main keyword to describe this session by
// : type a personal identifier

// http://localhost:3000/#/session/XDEyTj62nc8EpcCVf8qG/resources/hello
// Can mention that the custom string can not include forward slashes/ or spaces
