import React, { useState, useEffect, useContext } from 'react'
import '../App.css'
import { FirebaseConfig } from '../FirebaseConfig';
import { Link } from 'react-router-dom'
import { MainContext, PAGES } from './Contexts';






const SessionPage = (props) => {



  // if url has 5 / then have the template literal otherwise have url

  const mainContext = useContext(MainContext)

  const sessionId = mainContext.mainState.sessionId

  const [copySuccess, setCopySuccess] = useState('')

  const [didCopyYet, setDidCopyYet] = useState(false)

  // ! Rename the title; important piece of state

  const [RenameKeyword, setRenameKeyword] = useState(sessionId)

  // const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
  // const querySnapshot = await coll.where("keyword", "==", keyword).get();
  //! similar code to the QuestionLinkPage ??







  // TODO Change URL to include keyword / custom naming use of template literal? If naming is available otherwise
  // TODO or link to that url then copy it

  // If the url has 5 slashes (i.e. hasn't got the keyword already) then add the keyword
  //  However if the rename keyword is the 20 character long unique string just use the original url (i.e. so that not duplicated)
  // replace spaces with %20


  // ? Check this code works/ e.g. the non duplicates part console.log etc.
  // let url = window.location.href

  /* 
  if the keyword hasn't been added to the url then add it to the URL, 
  ALSO, if no keyword added still need to set the URL as the original
  If the length has more than 5 forward slashes, set the URL as the original
*/

  // !Code below no longer needed? Can comment out. Only require  url = window.location.href for if the the renameKeyword has not been changed (from initial state database generated string)

  // if ((url.match(/\//g) || []).length === 5) {
  //   url = `${url}/${RenameKeyword}`
  //   url = url.replace(/ /g, "%20")
  //   console.log('RAIN-Keyword', url)
  //   if ((url.Length - url.indexOf("/") - 1) === 20) {
  //     url = window.location.href
  //   }
  // } else {
  //   url = window.location.href
  //   console.log('URL', url)
  // }


  // Find and replace to test
  // https://localhost:3000/#/
  // https://plygn.link/#/

  async function copyToClipboard(e) {

    let url


    if (RenameKeyword != sessionId) {
      const urlShortenerCollName = FirebaseConfig.getFirebaseCollection(FirebaseConfig.urlShortenerCollName);
      var docRef = urlShortenerCollName.doc(RenameKeyword);

      const doc = await docRef.get();
      if (doc.exists) {
        const indexSessionId = doc.data()["sessionId"].indexOf(sessionId)
        if (indexSessionId != -1) {
          url = `https://plygn.link/#/${indexSessionId}/${RenameKeyword}`
        } else {
          const newDocument = doc.data();
          newDocument["sessionId"].push(sessionId);
          await docRef.update(newDocument);
          url = `https://plygn.link/#/${newDocument["sessionId"].length - 1}/${RenameKeyword}`
        }
        console.log("Document data:", doc.data());
      } else {
        const item = {
          sessionId: [sessionId]
        };
        const document = await urlShortenerCollName.doc(RenameKeyword).set(item);
        url = `https://plygn.link/#/0/${RenameKeyword}`
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    else {
      url = window.location.href
    }
    // change from 7 to 8 (https:// or http://)

    const visualUrl = url.slice(8);

    setCopySuccess(`Session Link: ${visualUrl} – Copied to Clipboard!`)
    // setCopySuccess('Session Link Copied to Clipboard!')
    setDidCopyYet(true)

    await navigator.clipboard.writeText(visualUrl);
  }

  // !sessionId variable is saved in CONTEXT from the router and in the QuestionSubmissionPage therefore we just need to get the value out of context using mainContext.mainState.sessionId.
  //! the sessionId is then used to get the correct collection data from firebase

  async function _getSessionInfo() {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);
    const document = (await coll.doc(sessionId).get()).data()
    console.log(document)
    return document
  }

  // sessionInfo is an object that is fetched from the database
  const [sessionInfo, updateSessionInfo] = useState({
    keywords: [],
    renameTitle: "",
    timestamp: {}
  })


  useEffect(() => {
    async function fetchData() {
      const sessionInfo = await _getSessionInfo()
      updateSessionInfo(sessionInfo)
    }
    fetchData();
  }, [mainContext.mainState.nextPageState])

  // !MODIFY KEYDOWN FUNCTION TO DISPATCH SESSIONKEYWORD AND SESSION TIME - so that the information is available on the linkInfoPage

  // SessionTime is from sessionInfo.timestamp.seconds 

  function _handleKeyDownKeywordQuery(e) {
    if (e.key === 'Enter') {

      const k = e.target.value.trim().toLowerCase()

      const matchesLowercase = (element) => element.toLowerCase() === k;

      if (sessionInfo.keywords.some(matchesLowercase)) {
        mainContext.mainStateDispatch({ type: 'session-time', sessionTime: sessionInfo.timestamp.seconds });
        mainContext.mainStateDispatch({ type: 'keyword', keyword: k });
        mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.VIEW_RESULTS });
      }
      else {
        alert('Entered text is not a session Keyword')
      }

    }
  }

  async function _handleBack(e) {
    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_QUESTIONS });
  }


  //! CODE TO Check if item exists in local storage to check that they can rename the session i.e. if the dropdown is rendered?

  const [UserHasSessionId, updateUserHasSessionId] = useState(true)

  useEffect(() => {
    async function fetchData() {

      const storageKey = "storedSessionUrls"
      const currentData = localStorage.getItem(storageKey)
      let storedSessionUrls = [];
      if (currentData) {
        storedSessionUrls = JSON.parse(currentData);
      }

      if (storedSessionUrls.includes(sessionId) === true) {
        updateUserHasSessionId(true)
      } else {
        updateUserHasSessionId(false)
      }
    }
    fetchData();
  }, [])

  //  ! Deletes by sessionId but it doesn't need to be passed through into the function
  //! It is already defined, so can use straight away
  // Filtered by sessionId and change component page to home

  function _deleteSessionFromLocalStorage() {

    const storageKey = "storedSessionUrls"
    const currentData = localStorage.getItem(storageKey)
    let storedSessionUrls = [];
    if (currentData) {
      storedSessionUrls = JSON.parse(currentData);
    }

    console.log('sessionId:', sessionId)
    const filteredSessionUrls = storedSessionUrls.filter(item => item !== sessionId)

    console.log(filteredSessionUrls)
    localStorage.setItem(storageKey, JSON.stringify(filteredSessionUrls))

    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_QUESTIONS })
  }


  async function _saveSharedSession() {

    const sessionUrlsColl = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);

    const document = await sessionUrlsColl.add(sessionInfo);

    const storageKey = "storedSessionUrls";

    const currentData = localStorage.getItem(storageKey);
    let storedSessionUrls = [];
    if (currentData) {
      storedSessionUrls = JSON.parse(currentData);
    }
    storedSessionUrls.push(document.id);
    localStorage.setItem(storageKey, JSON.stringify(storedSessionUrls));

    mainContext.mainStateDispatch({ type: 'save-shared-session-Id', saveSharedSessionId: document.id })

    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.ENTER_QUESTIONS })
  }

  async function _openSessionEditPage() {
    // mainContext.mainStateDispatch({ type: 'session-Id', sessionId: sessionId })
    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.EDIT_PAGE })
  }






  //! CODE so that only the keyphrases with links are shown
  // ! As this useEffect relies on the state of sessionInfo updating. ie. this useEffect after the useEffect where sessionInfo is updated
  // Also this useEffect is filtering the data from the database instead of the data in Context

  const [sessionKeyphrasesWithLinksData, updateSessionKeyphrasesWithLinksData] = useState([])

  useEffect(() => {
    async function fetchData() {

      console.log('sessionInfo.keywordsFullArray', sessionInfo.keywords)

      // new array that has links, phrases are pushed inside
      let hasLinks = []
      for (const keyPhrase in sessionInfo.keywords) {

        let keyPhraseValue = sessionInfo.keywords[keyPhrase]
        console.log('keyPhraseValue', keyPhraseValue)

        const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
        const querySnapshot = await coll.where("keyword", "==", keyPhraseValue).get();
        if (querySnapshot.docs.length !== 0) {
          hasLinks.push(keyPhraseValue)
        }
        console.log('hasLinksInLoop', hasLinks)

      }
      updateSessionKeyphrasesWithLinksData(hasLinks)
    }
    fetchData()

  }, [sessionInfo])


  // ! DROPDOWN MENU CODE 

  async function _SaveSessionRename(selectedKeyword, sessionId) {
    const sessionUrlsColl = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);

    console.log('TESTSESSIONID?', sessionId)

    const matchedDocuments = await sessionUrlsColl.doc(sessionId).update({ renameTitle: selectedKeyword })
    console.log('DATABASE-RENAME', selectedKeyword)

  }





  // state to render the input box for custom rename or not
  const [isCustomRename, setCustomRename] = useState(false)

  //! Dropdown Menu Function on click to rename the session in the UI but also in the database

  function handleSort(event) {
    // console.log(event.target.value)
    if (event.target.value === 'custom') {
      setCustomRename(true)
    } else {
      setRenameKeyword(event.target.value)
      setCustomRename(false)
      _SaveSessionRename(event.target.value, sessionId)

    }


    // TODO Add code to update the database name
    // TODO on questionSubmission get into from database
    // TODO CUSTOM SESSIONID -input when value is custom`?
  }



  // ! Handle input of Custom Session text
  function _handleKeyDownCustomSession(e) {

    setRenameKeyword(e.target.value)
    if (e.key === 'Enter') {

      _SaveSessionRename(e.target.value, sessionId)
      setCustomRename(false)
    }
  }

  //! Map over the key phrases and save as JSX for dropdown menu
  let dropdownItems = []

  dropdownItems = sessionKeyphrasesWithLinksData.map((word, i) =>
    <option value={word} className="reviewsPageOption"
      key={i}>{word[0].toUpperCase() + word.substring(1)}
    </option>)

  // !  USE DATA FROM DATABASE TO UPDATE RenameKeyword initial state 
  // i.e. the rename title initial state is set as the sessionId on page mount (initial)
  // Hence the rename title is updated
  // Dependent on the useEffect to get the sessionInfo which has to run first - hence sessionInfo in the dependency array

  useEffect(() => {
    async function fetchData() {
      if (sessionInfo.renameTitle === "") {
        return RenameKeyword
      } else {
        setRenameKeyword(sessionInfo.renameTitle)
        // console.log('DataFromDatabase')
        return RenameKeyword
      }
    }
    fetchData();
  }, [sessionInfo])





  // ! RETURN RENDERING STARTS HERE
  return <div className="submit-question-container App-header" style={{ backgroundColor: "#50041ee6" }} >

    {UserHasSessionId && <nav className="feed-sort">

      <select className="Dropdown-rename"
        onChange={
          handleSort}
      >
        <option value={sessionId} defaultValue> Rename Session: </option>
        {dropdownItems}
        <option value="custom" > CUSTOM 🚀 </option>
      </select>
    </nav>}

    <div>

      <button className="Session-delete-button">
        <Link to={'/'} className="Delete-session-button" onClick={_deleteSessionFromLocalStorage} > ✔️  Remove  </Link>
      </button>

      {!UserHasSessionId && <button className="Session-save-button"> <Link to={'/'} className="Session-save-text" onClick={_saveSharedSession} > Save Session  </Link> </button>}

      {UserHasSessionId && <button className="Session-edit-button">  <Link to={`/session/${sessionId}/edit`} className="Session-edit-text" onClick={_openSessionEditPage} > Edit </Link> </button>}

    </div>


    <h3 style={{ marginTop: 0 }}> Session Id: <br />
      <small> {RenameKeyword} </small></h3>
    <h3>



      {isCustomRename && <div> <input className="Text-input-questionsubmissionpage Enter-question-styling Session-rename-input" type="text" placeholder="Enter Custom Session Id: [Enter ↩︎]" onKeyDown={_handleKeyDownCustomSession} autoFocus /></div>}

      {/*TODO Create typing and submission function */}


      <span style={{ color: "#d7ff05" }}> Search Keywords: </span>

      {sessionKeyphrasesWithLinksData.map((word, i) =>
        <ul className="Spacing-bullet-points"
          key={i}>{word[0].toUpperCase() + word.substring(1)}
        </ul>)}

    </h3>
    {/* <p> {sessionInfo.timestamp.seconds} </p> */}


    {/* Link to changes the URL to the home, then the function changes the component to the home. 
Also as the session URL isn't there then the if statement in QuestionsSubmission isn't triggered */}

    {/* Could put the onClick inside the h3 instead of inside the Link if you want the clickable area to be less wide of an area */}

    <Link to={'/'} onClick={_handleBack}>
      <h3 style={{ color: "#05ff82" }}
      > <span> &nbsp;</span>Home ➶</h3>

    </Link>



    <input className="Text-input-questionsubmissionpage Enter-question-styling" type="text" placeholder=" Search session keyword: [Enter ↩︎]  " onKeyDown={_handleKeyDownKeywordQuery} autoFocus />

    {/* <input type="text" onKeyDown={_handleKeyDownKeywordQuery} /> */}

    {/* if the URL hasn't got a keyword i.e. it is 5 forward slashes long then use the original URL;
 how if it has then create the short plygn.link URL */}

    <button style={{ color: didCopyYet ? "#148419" : "red" }} className="Session-share-button" onClick={copyToClipboard}  >Share Session 🌐</button>
    <p className="Session-share-response" >{copySuccess}</p>
  </div>


}

// Use the parameters to make a call to the database
// ie. the time, and the keywords
// Can they share the link and other people still access it? 

export default SessionPage