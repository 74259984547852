import React, { useState, useEffect } from 'react'
import '../App.css'
import { Redirect, Link } from 'react-router-dom'
import { FirebaseConfig } from '../FirebaseConfig';
import { RadarChart } from 'react-vis'



//! Method to filter from LinkInfo Page

const RadarChartComponent = (props) => {

  //  link the Shape to the correct sessionId page using the sessionId
  return (
    <div>
      <h2 className="Trending-page-keyword-title"> Keyword: {props.keyword.charAt(0).toUpperCase() + props.keyword.slice(1)} </h2>
      <h2 className="Trending-page-reaction-count"> Reaction count: {props.totalReactionCount} </h2>


      {/* REMEMBER that this is not responsive, only loads a different size on page load/ render
      Based on the width there are different configurations for the radar chart */}
      { window.screen.width < 800 && <div className="Radar-chart-trending-page">
        <RadarChart

          animation
          data={props.data}
          domains={props.domains}
          style={{
            polygons: {
              fillOpacity: 0,
              strokeWidth: 3
            },
            axes: {
              text: {
                opacity: 1
              }
            },
            labels: {
              textAnchor: 'middle',
              fontSize: 22,
              // defaultFontColor: '#ffffff',
            }
          }}
          margin={{
            left: 90,
            top: 40,
            bottom: 40,
            right: 80
          }}
          tickFormat={t => ''}
          width={410}
          height={340}
        >
          {/* <CircularGridLines
    tickValues={[...new Array(10)].map((v, i) => i / 10 - 1)}
  /> */}
        </RadarChart>
      </div>}


      { window.screen.width >= 800 && <div className="Radar-chart-trending-page">
        <RadarChart
          animation
          data={props.data}
          domains={props.domains}
          style={{
            polygons: {
              fillOpacity: 0,
              strokeWidth: 3
            },
            axes: {
              text: {
                opacity: 1
              }
            },
            labels: {
              textAnchor: 'middle',
              fontSize: 24,
              // defaultFontColor: '#ffffff',
            }
          }}
          margin={{
            left: 160,
            top: 40,
            bottom: 40,
            right: 160
          }}
          tickFormat={t => ''}
          width={600}
          height={360}
        >
          {/* <CircularGridLines
            tickValues={[...new Array(10)].map((v, i) => i / 10 - 1)}
          /> */}
        </RadarChart>

      </div>}

    </div>)

}

const TrendingActivityPage = (props) => {

  // as mainContext.mainState.rangeSessionTime not available as the page is not in the context
  // just hard code the numerical value .e.g. 7200 equals 2hrs

  async function _getKeywordInfo(keyword, sessionTimestamp, sessionDocumentId) {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
    const promises = [];
    // console.log("KEYWORD IN FUNCTION", keyword)
    promises.push(coll.where("keyword", "==", keyword).get());
    const querySnapshots = await Promise.all(promises);

    if (querySnapshots) {
      const document = querySnapshots[0].docs[0];
      if (document) {
        let filteredLinks = document.data().links
        if (sessionTimestamp != 0) {

          const sessionRangeStart = sessionTimestamp - 7200;
          const sessionRangeEnd = sessionTimestamp + 7200;

          const timeIsInRange = (e) => e.seconds >= sessionRangeStart && e.seconds <= sessionRangeEnd;
          const linkIsInSession = element => element.timeAdded === undefined || element.timeAdded.some(timeIsInRange);
          // some check is at least one of values is in the array
          filteredLinks = document.data().links.filter(linkIsInSession)
          // console.log("FILTER-LINKS-IN-FUNCTION", filteredLinks)
        }

        return [document, filteredLinks, sessionDocumentId];
      }
    }
    // console.log("LinkInfoPage - did not return")
  }


  // aim to choose the document that are within the time range only





  async function _loopCollectionKeywords() {

    let highestReactionCountArray = []

    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);

    const allSessionsQuerySnapshot = await coll.get()
    // console.log("ARRAY-ALLSESSIONS", allSessionsQuerySnapshot.docs)
    // could filter straight away

    const allSessionArray = allSessionsQuerySnapshot.docs

    // console.log("First-ITEM", allSessionArray[1].data())
    // TRENDING TIME CONFIGURATION current 90days

    let filteredSessions
    const sessionRangeStart = Math.floor(new Date().getTime() / 1000.0) - 7776000
    const sessionRangeEnd = Math.floor(new Date().getTime() / 1000.0)


    // creation a function which has to pass condition

    const sessionInTimeRange = element => element.data().timestamp === undefined || element.data().timestamp.seconds >= sessionRangeStart && element.data().timestamp.seconds <= sessionRangeEnd

    filteredSessions = allSessionArray.filter(sessionInTimeRange)

    // .filter  can only be used on an array

    // console.log("FILTERED SESSIONS", filteredSessions)

    for (const oneSession of filteredSessions) {

      const sessionKeywordsArray = oneSession.data().keywords
      const sessionTimestamp = oneSession.data().timestamp.seconds
      const documentIdFromFirebase = oneSession.id

      // for each document from the sessionUrlsColl database use keyword to get links from other database
      // console.log("1-SESSION-DOCUMENT-ID", documentIdFromFirebase, typeof (documentIdFromFirebase))

      for (const keyword of sessionKeywordsArray) {
        // console.log('KEYWORD', keyword)
        // need the sessionId to filter by time for the links!

        const docLinksArray = await _getKeywordInfo(keyword, sessionTimestamp, documentIdFromFirebase)

        if (docLinksArray) {

          const dbDoc = docLinksArray[0]
          const filteredLinks = docLinksArray[1]
          const sessionDocumentId = docLinksArray[2]
          if (dbDoc) {
            // console.log("KEYWORD", keyword)
            // console.log("LINKS-DATA-RETURNED", filteredLinks)

            let totalReactionCount = 0
            for (const linkObject of filteredLinks) {
              // console.log("LINK-OBJECT", linkObject)
              totalReactionCount = totalReactionCount + linkObject.reactionCount
            }
            // console.log("SUM-REACTION-COUNT", totalReactionCount)
            // console.log("IN-LOOP-SESSION-DOCUMENT-ID", sessionDocumentId)

            // for each object in the array
            // if the object value matches the keyword and the object reaction count is higher
            // then replace the object values with new values

            // console.log("PRINT-ARRAY", highestReactionCountArray)

            if (highestReactionCountArray.length === 0) {

              // console.log("ARRAY-ZERO-PUSHED")

              highestReactionCountArray.push({
                totalReactionCount: totalReactionCount,
                sessionDocumentId: sessionDocumentId,
                keyword: keyword,
              })

            }


            for (let i = 0; i < highestReactionCountArray.length; i++) {
              if ((highestReactionCountArray[i].keyword == keyword) && (highestReactionCountArray[i].totalReactionCount <= totalReactionCount)) {
                // console.log("KEY-INTO-OBJECT")

                highestReactionCountArray[i].totalReactionCount = totalReactionCount

                // console.log("ORIGINAL-SESSION", highestReactionCountArray[i].sessionDocumentId)
                // console.log("REPLACED-SESSION", sessionDocumentId)

                highestReactionCountArray[i].sessionDocumentId = sessionDocumentId


                // console.log("KEY-INTO-SESSION-DOCUMENT", highestReactionCountArray[i].sessionDocumentId)

                highestReactionCountArray[i].keyword = keyword

                // console.log("KEY-INTO-KEYWORD", highestReactionCountArray[i].keyword)

              }
            }

            // console.log("2-ARRAY-AFTER", highestReactionCountArray)



            // if the keyword does not exist for any of the objects in the array then keywordNotExists = true

            let keywordNotExist = false;

            const keyWordExists = (object) => object.keyword == keyword

            const booleanResult = highestReactionCountArray.some(keyWordExists)

            // console.log("BOOLEAN-RESULT", booleanResult)

            if (booleanResult === false) {
              keywordNotExist = true
              // console.log("KEYWORD-NOT-EXIST")

            }

            // now you have an array
            // with no duplicate objects with the same values for the key "keyword"


            if (keywordNotExist === true) {
              highestReactionCountArray.push({
                totalReactionCount: totalReactionCount,
                sessionDocumentId: sessionDocumentId,
                keyword: keyword,
              });
              // console.log('OBJECT-PUSHED-TO-ARRAY', highestReactionCountArray)
            }

            // console.log("3-ARRAY-AFTER", highestReactionCountArray)


            // highestReactionCountArray.push({
            //   totalReactionCount: totalReactionCount,
            //   sessionDocumentId: sessionDocumentId,
            //   keyword: keyword,
            // });


            // console.log("IN-LOOP-highestReactCountArray", highestReactionCountArray)

            // number of shapes shown CONFIGURATION

            if (highestReactionCountArray.length > 5) {
              // Remove the lowest object with the lowest count
              highestReactionCountArray.sort((a, b) => b.totalReactionCount - a.totalReactionCount);
              highestReactionCountArray.pop();
              // console.log("POP-highestReactCountArray", highestReactionCountArray)
            }



            // highestReactionCountArray => [{totalReactCount: 5, sessionDocumentId: "0wiodaufjsds", keyword: "apple"}]
          }
        }

      }

    }

    // console.log("FINAL-highestReactCountArray", highestReactionCountArray.toString(), "END")
    // check if the VALUE is actually available? string is a constant

    return highestReactionCountArray

  }


  async function _getSessionInfo(sessionDocumentId) {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName)
    const document = (await coll.doc(sessionDocumentId).get()).data()
    // console.log(document)
    return document
  }

  // const [sessionInfo, updateSessionInfo] = useState({
  //   keywords: [],
  //   renameTitle: "",
  //   timestamp: {}
  // })


  async function _loopDataForShapes(returnedReactionArray) {

    // console.log("RETURNED-REACTION-ARRAY-1", returnedReactionArray, typeof (returnedReactionArray))

    //  returnedReactionArray is an Array of objects

    // [ 
    //     { totalReactionCount: totalReactionCount,
    //   sessionDocumentId: sessionDocumentId,
    //   keyword: keyword } 
    // ]


    let radarChartData = []

    for (const keywordObject of returnedReactionArray) {

      // console.log("IN-FOR-LOOP", returnedReactionArray, typeof (returnedReactionArray))
      // console.log("SESSION-ID", keywordObject.sessionDocumentId)
      // console.log("KEYWORD", keywordObject.keyword)

      const sessionInfo = await _getSessionInfo(keywordObject.sessionDocumentId)
      // console.log('SESSION-INFO', sessionInfo)
      // to get the links need the sessionTimestamp

      const totalReactionCount = keywordObject.totalReactionCount
      const sessionDocumentId = keywordObject.sessionDocumentId
      const sessionTimestamp = sessionInfo.timestamp.seconds
      const keyword = keywordObject.keyword

      //! third variable as sessionDocumentId and return value may be required for function to work
      // get array of links for that keyword

      const docLinksArray = await _getKeywordInfo(keyword, sessionTimestamp, sessionDocumentId);

      // console.log("DOCS-LINKS-ARRAY", docLinksArray )

      if (docLinksArray) {
        const dbDoc = docLinksArray[0]
        const filteredLinks = docLinksArray[1]
        const sessionDocumentId = docLinksArray[2]
        if (dbDoc) {

          // console.log("SHAPE-KEYWORD", keyword)
          // console.log("SHAPE-LINKS-DATA-RETURNED", filteredLinks)

          // filtered links is an array of link objects
          // [  
          //   { link: "string"
          //    reactionCount: 4
          //    timeAdded: {} }
          // ]

          // filteredLinks loop over and create shapes 
          // (repeat loop for all)
          // Create shapes using the links

          const getHostname = (url) => {
            // use URL constructor and return hostname
            return new URL(url).hostname;
          }

          let dataItem = {}
          for (const data of filteredLinks) {
            dataItem[
              (getHostname(data['link'])).replace("www.", "").replace(".com", "").replace(".co.uk", "").replace(".org", "").replace(".net", "").replace(".gov", "")
            ] = data['reactionCount'] + 5
          }

          const DATA = [dataItem];

          const DOMAIN = filteredLinks.map((d, i) => ({
            "name":
              (getHostname(d["link"])).replace("www.", "").replace(".com", "").replace(".co.uk", "").replace(".org", "").replace(".net", "").replace(".gov", "")
            , "domain": [0, 20]
          }));

          // console.log("FILTERED LINKS", filteredLinks, { DATA }, { DOMAIN })

          // return - data, domain, as an array of objects
          // map over this array of objects passing the information to another component
          // save this map as a const
          // show the const in the render of the page
          // create another component

          radarChartData.push({
            sessionDocumentId: sessionDocumentId,
            totalReactionCount: totalReactionCount,
            keyword: keyword,
            data: DATA,
            domain: DOMAIN
          })

        }
      }

    }

    // console.log("RADAR-CHART-DATA", radarChartData)
    return radarChartData

  }


  const [loadingState, updateLoadingState] = useState(true)

  const [radarChart, updateRadarChart] = useState([])

  useEffect(() => {
    async function fetchData() {
      const value = await _loopCollectionKeywords()
      // console.log("VALUE 1", value );
      const value2 = await _loopDataForShapes(value)
      // console.log("VALUE 2", value2)
      updateRadarChart(value2)
      updateLoadingState(false)
    }
    fetchData();
  }, [])


  const allRadarCharts = radarChart.map((data, i) => <RadarChartComponent key={i} index={i} data={data.data} domains={data.domain} alldata={data} keyword={data.keyword} totalReactionCount={data.totalReactionCount} sessionDocumentId={data.sessionDocumentId} />);


  return <div>

    <div className="App">
      <h1 className="Main-title" >Search Polyg♻️n</h1>
      <h4 className="Live-demo" style={{ backgroundColor: "#ffe4c482", color: "crimson" }}> BETA - Live Demo  🚀</h4>
    </div>
    <h2 className="coming-soon">Trending 🎩</h2>


    <Link to={'/'}>
      <h1 className="create"> Create
      <span style={{ color: "blue", fontWeight: 900 }}> <span>&nbsp;</span> ⤻  </span> </h1>
    </Link>

    {loadingState && <h3 className="loading"> Loading ...
    </h3>}

    {allRadarCharts}

  </div>

}

export default TrendingActivityPage