import React, { useState, useEffect, useContext } from 'react';
import '../App.css';

import { TextAnalyticsClient } from 'azure-cognitiveservices-textanalytics';
import { ApiKeyCredentials } from 'ms-rest';
import firebase from 'firebase';
import { FirebaseConfig } from '../FirebaseConfig';
import { MainContext, PAGES } from './Contexts';

import { Link } from 'react-router-dom'
import heart from '../assets/heartimage.png'
import twitter from '../assets/twitter.png'
import hexagon from '../assets/hexagon.png'
import diamond from '../assets/diamond.png'
import triangle from '../assets/triangle.png'
import pentagon from '../assets/pentagon.png'

import moment from 'moment'


// Component - functional component rendered within another component when returned
function QuestionList(props) {
  const questions = props.questions;
  console.log('questions', questions);
  const listItems = questions.map((q, i) => <li key={i}>{q}</li>);
  return (
    <ul>{listItems}</ul>
  );
}


const QuestionSubmissionPage = (props) => {

  const mainContext = useContext(MainContext)

  //! CODE so that only the keyphrases with links only are shown
  const [keyphrasesWithLinksData, updatekeyphrasesWithLinksData] = useState([])

  useEffect(() => {
    async function fetchData() {

      // new array that has links, phrases are pushed inside
      let hasLinks = []

      // console.log('mainContext.mainState.submittedQuestionKeyPhrases', mainContext.mainState.submittedQuestionKeyPhrases)
      for (const keyPhrase in mainContext.mainState.submittedQuestionKeyPhrases) {

        let keyPhraseValue = mainContext.mainState.submittedQuestionKeyPhrases[keyPhrase]

        const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.keywordQuestionsBankCollName);
        const querySnapshot = await coll.where("keyword", "==", keyPhraseValue).get();
        console.log('querySnapshot', querySnapshot)
        if (querySnapshot.docs.length !== 0) {
          hasLinks.push(keyPhraseValue)
        }
        console.log('hasLinksInLoop', hasLinks)

      }
      updatekeyphrasesWithLinksData(hasLinks)
    }
    fetchData()

  }, [])


  /* !START FOR ROUTER CONFIGURATIONS FROM MainAppSearchPolygon
      To pass in VARIABLES and to save in CONTEXT to then use the information
      in another component AND in order to change the page
  */

  if (props.sessionId) {

    const sessionId = props.sessionId
    mainContext.mainStateDispatch({ type: 'session-Id', sessionId: sessionId })

    if (props.isEditRoute === true) {
      mainContext.mainStateDispatch({
        type: 'next-page', nextPageState: PAGES.EDIT_PAGE
      })
    } else {
      mainContext.mainStateDispatch({
        type: 'next-page', nextPageState: PAGES.SESSION_PAGE
      })
    }

    console.log(mainContext.mainState)
  }

  /* END FOR ROUTER CONFIGURATIONS FROM MainAppSearchPolygon */

  const analyticsKey = "47f007852d9b40f4a1c31d8a8e2a5bbf";
  const analyticsEndpoint = "https://westus2.api.cognitive.microsoft.com/";

  const [questionsData, updateQuestionsData] = useState({
    questions: []
  });

  const [keywordQueryData, updateKeywordQueryData] = useState({
    keywordQuery: ""
  });

  const [loadingState, updateLoadingState] = useState({
    submissionInProgress: false
  });

  /* - if empty input the function returns before entering
  - if there is no question mark, it adds one
  - capitalise the first letter in the string
  */

  function _handleKeyDown(e) {
    if (e.key === 'Enter') {

      let trimmedInput = e.target.value.trim()
      if (trimmedInput === "") {
        return
      }

      if (trimmedInput.indexOf('?') != trimmedInput.length - 1) {
        trimmedInput = `${trimmedInput}?`
      }

      trimmedInput = trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1)

      updateQuestionsData({
        questions: [...questionsData.questions, trimmedInput]
      })
      e.target.value = null;
    }
  }

  function _getLocationCoordinates() {
    return new Promise((resolve, reject) => {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async function (location) {
          resolve(location.coords);
        });
      } else {
        resolve(0);
      }
    });
  }

  // TODO MODIFY _createAndSaveSessionUrl FUNCTION FOR IF didSubmitYet is true to merge into existing session instead of creating NEW SESSION

  async function _handleSubmit(e) {

    // reset the sessionTime to 0, so that global search works i.e. the if statement on LinkInfoPage is not executed; and all the keyword information is returned

    mainContext.mainStateDispatch({ type: 'session-time', sessionTime: 0 });

    updateLoadingState({ submissionInProgress: true });
    FirebaseConfig.getFirebaseAnalytics().logEvent('question_submission_attempt');
    if (questionsData.questions.length === 0) {
      alert("Enter each question before submitting all questions");
      return;
    }

    const toAddToDatabase = [];
    const currentTime = new Date();

    // const coords = await _getLocationCoordinates();
    for (const question of questionsData.questions) {
      if (question) {
        toAddToDatabase.push({
          'text': question,
          // 'location': new firebase.firestore.GeoPoint(coords.latitude, coords.longitude),
          'timestamp': new firebase.firestore.Timestamp.fromDate(currentTime)
        });
      }
    }

    let submittedQuestionKeyPhrases = await _analyseQuestions(toAddToDatabase);
    console.log('toAddToDatabase', toAddToDatabase);
    updateLoadingState({ submissionInProgress: false });
    if (submittedQuestionKeyPhrases.length === 0) {
      alert("No key phrases available for these questions.");
    } else {

      // Here we limit submittedQuestionsKeyPhrases to 4 with SLICE
      await _saveToDatabase(toAddToDatabase);
      submittedQuestionKeyPhrases = submittedQuestionKeyPhrases.slice(0, 4);
      await _createAndSaveSessionUrl(currentTime, submittedQuestionKeyPhrases);


      mainContext.mainStateDispatch({ type: 'submit-question', submittedQuestionKeyPhrases: submittedQuestionKeyPhrases });
      mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.VIEW_QUESTIONS });
    }

  }

  async function _handleQuickSessionSubmit(e) {

    // reset the sessionTime to 0, so that global search works i.e. the if statement on LinkInfoPage is not executed; and all the keyword information is returned

    mainContext.mainStateDispatch({ type: 'session-time', sessionTime: 0 });

    const currentTime = new Date();
    const submittedQuestionKeyPhrases = []

    const sessionId = await _createAndSaveSessionUrl(currentTime, submittedQuestionKeyPhrases);

    mainContext.mainStateDispatch({ type: 'session-Id', sessionId: sessionId })


    mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.EDIT_PAGE });

  }


  function _handleKeyDownKeywordQuery(e) {
    if (e.key === 'Enter') {
      const k = e.target.value.trim().toLowerCase();
      updateKeywordQueryData({
        keywordQuery: k
      });
      // e.target.value = null;
      mainContext.mainStateDispatch({ type: 'keyword', keyword: k });
      mainContext.mainStateDispatch({ type: 'next-page', nextPageState: PAGES.VIEW_RESULTS });
    }
  }

  // !TEXT ANALYTICS configuration

  async function _analyseQuestions(questionsToAddToDatabase) {
    const creds = new ApiKeyCredentials({ inHeader: { 'Ocp-Apim-Subscription-Key': analyticsKey } });
    let analyticsServicesApi = new TextAnalyticsClient(creds, analyticsEndpoint);
    const yourKeyphrasesSet = new Set();

    const analyticsInput = [];
    for (let index = 0; index < questionsToAddToDatabase.length; index++) {
      const element = questionsToAddToDatabase[index]['text'];
      analyticsInput.push({
        language: 'en',
        id: index + "",
        text: element
      });
    }

    const analyticsResult = await analyticsServicesApi.keyPhrases({
      showStats: false,
      multiLanguageBatchInput: {
        documents: analyticsInput
      }
    });

    for (let index = 0; index < analyticsResult.documents.length; index++) {
      const loweredKeyPhrases = analyticsResult.documents[index].keyPhrases.map(v => v.toLowerCase());
      const loweredAndSplitKeyPhrases = [];
      for (const kP of loweredKeyPhrases) {
        loweredAndSplitKeyPhrases.push(...kP.split(" "));
      }
      questionsToAddToDatabase[index]['keyPhrases'] = loweredAndSplitKeyPhrases;
      loweredAndSplitKeyPhrases.forEach(item => yourKeyphrasesSet.add(item))
    }

    let your_keyphrases = [];
    for (const item of yourKeyphrasesSet) {
      your_keyphrases.push(item);
    }
    return your_keyphrases;
  }

  async function _saveToDatabase(toAddToDatabase) {
    const questionBank = FirebaseConfig.getFirebaseCollection();
    for (const item of toAddToDatabase) {
      await questionBank.add(item);
    }
  }


  // TODO HERE boundaries as well as time and keyword, could also have Location

  // Retrieving session data from the database should be dependent on weather the session is saved (context state needed??)
  const [isSessionSaved, setIsSessionSaved] = useState(false)

  async function _createAndSaveSessionUrl(currentTime, keyPhrases) {
    const sessionUrlsColl = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);
    const item = {
      keywords: keyPhrases,
      renameTitle: "",
      timestamp: new firebase.firestore.Timestamp.fromDate(currentTime)
    };
    const document = await sessionUrlsColl.add(item);

    const storageKey = "storedSessionUrls";

    const currentData = localStorage.getItem(storageKey);
    let storedSessionUrls = [];
    if (currentData) {
      storedSessionUrls = JSON.parse(currentData);
    }
    storedSessionUrls.push(document.id);
    localStorage.setItem(storageKey, JSON.stringify(storedSessionUrls));

    return document.id;

    //! may need to useState so that you have a variable that isSessionInfo is saved true then the useEffect can rely on this info
  }

  // Getting data from local storage using the session keys and mapping to JSX to later render on the page

  //TODO make the rendering of the page show the date that the session was created instead of the session ID

  //TODO Style the session links, CSS instead of inline, circles e.g. and dates

  // TODO as session Id is mapped from information from local storage where the Id equal


  // function SessionSaved() {
  //   setIsSessionSaved(true)

  // }

  // SessionSaved()

  const sessionData = localStorage.getItem("storedSessionUrls")
  let sessionInfo = []
  if (sessionData) {
    sessionInfo = JSON.parse(sessionData)
  }

  // TODO TIME of the particular sessions/ similar to CONTINUE / session page
  // !RENDERING SESSION INFO on the page

  async function _getSessionInfo(passedInfo) {
    const coll = FirebaseConfig.getFirebaseCollection(FirebaseConfig.sessionUrlsCollName);
    const document = (await coll.doc(passedInfo).get()).data()
    console.log('DOCUMENT-TEST', document)
    return document
  }

  // TODO Note that the app crashes in DEVELOPMENT when there are no sessionIds
  //! error message: 
  // Unhandled Rejection (FirebaseError): Function CollectionReference.doc() requires its first argument to be of type non-empty string, but it was: undefined

  // sessionInfo is an array of the session Id strings
  // sessionObject is an object
  const [sessionObject, updateSessionInfo] = useState({
    keywords: [],
    renameTitle: "",
    timestamp: {}
  })

  const [timeFullArray, updatetimeFullArray,] = useState([])
  const [renameFullArray, updateRenameFullArray,] = useState([])

  // prevent on mount ???? with dependancy array/ test
  // return () => {
  // }

  //!CODE DISPLAYING THE SESSION TIMES PER SESSION FOR questionSubmissionPage

  useEffect(() => {

    async function fetchData() {

      let timeValuesArray = []
      // Create an array of time values and push values into

      let renameValuesArray = []
      // Create an array of rename values and push values into

      //////////////////////!

      // Loop over the sessionInfo Array of Session Ids
      for (const session of sessionInfo) {

        // start of loop??
        console.log('useEffect CALLED!!')
        const sessionObject = await _getSessionInfo(session)
        // pass info to asyc function ??

        updateSessionInfo(sessionObject)

        // save info in array empty array??
        console.log('SESSION-INFO-IN', sessionObject)

        console.log('TIME VALUES', sessionObject.timestamp.seconds)
        let sessionTime = sessionObject.timestamp.seconds

        let utcSeconds = sessionTime;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);
        console.log('TIME CONVERTED', d)


        // ! convert time values in seconds to dates hook? moments 

        let formattedTime = moment(d).format('DD/MM - HH:mm')

        timeValuesArray.push(formattedTime)

        ////////////////////!

        let sessionRenameTitle = sessionObject.renameTitle

        renameValuesArray.push(sessionRenameTitle)

      }

      console.log('TIME VALUES ARRAY', timeValuesArray)
      updatetimeFullArray(timeValuesArray)

      console.log('HAPPY RENAME VALUES', renameValuesArray)
      updateRenameFullArray(renameValuesArray)

    }
    fetchData();

  }, [])

  // ! Mapping over sessions, timeFullArray AND renameFullArray
  // If renameFullArray[index] is "" then substring of session is used

  const listSessions = sessionInfo.map((session, index) =>

    <div>
      <li className="Session-links Spacing-bullet-points" key={index}>
        <Link to={`/session/${session}`} style={{ color: "#d7ff05" }}>
          <small>
            {timeFullArray[index]} – Id: <span>  </span>
            {renameFullArray[index] === "" ?
              session.substr(0, 5) : renameFullArray[index]}
          </small>
        </Link>
        {mainContext.mainState.saveSharedSessionId === session && <small style={{ color: "green" }}> &nbsp; Saved Session </small>}
      </li>
    </div>
  )



  console.log('listSessions', listSessions)


  // Refresh page - when click on the sessions, so that back to the original state of the page
  async function _refreshPage() {
    window.location.reload()
  }







  return (
    <div>
      <header className="App-header submit-question-container" style={{ backgroundColor: mainContext.mainState.didSubmitYet ? "#07bdf70f" : "#f707580f" }} >
        <input className="Text-input-questionsubmissionpage Enter-question-styling" type="text" placeholder=" Enter a question: " onKeyDown={_handleKeyDown} autoFocus />

        {/* If there is more than one question then the info part does not appear */}
        {questionsData.questions.length < 1 && <h6> [ Press Enter Key  ↩︎ ] </h6>}

        {questionsData.questions.length < 1 && <h4 className="Or-text-styling"> OR </h4>}


        {questionsData.questions.length < 1 &&
          <button onClick={_handleQuickSessionSubmit} className="Quick-session-button"> Create – Quick Session <span className="bolt-quick-sesssion-home" > ⚡️ </span></button>
        }

        {questionsData.questions.length < 1 &&
          <Link to={'/about'}>
            <h6 className="What-is-search-polygon"> What is Search Polygon? <br /> Click here to learn more
            <span style={{ color: "#57b8ff", fontWeight: 900 }} ><small> <span>&nbsp;</span> ⓘ</small> </span>
              <span style={{ color: "#ffed00", fontWeight: 900 }}> ⤻  </span> </h6>
          </Link>
        }
        {questionsData.questions.length < 1 &&
          <a href="https://twitter.com/searchpolygon" target="_blank">
            <img className="twitter" src={twitter} width="70px" />
          </a>
        }

        {questionsData.questions.length < 1 &&
          <Link to={'/trending'}>
            <h3 className="View-trending Trending-heading-style"> View Trending </h3>
            <div className="View-trending View-shapes">
              <div className="fadein">



                <img id="diamond" src={diamond} />
                <img id="hexagon" src={hexagon} />
                <img id="triangle" src={triangle} />
                {/* <img id="pentagon" src={pentagon} /> */}
              </div>
            </div>
          </Link>
        }

        {/* https://www.rawpixel.com/image/2258107/free-illustration-png-colorful-dark-green */}

        {/* https://css-tricks.com/the-shapes-of-css/ */}

        {/* https://stackoverflow.com/questions/17954874/cross-fade-between-images-with-css-in-loop */}


        {/* Conditional based on both the question length less than one AND if they have submitted a question - note that these two variables change as the events happen 

        - More data transfer capacity required to uncomment code below and show the GIF on live version of code
        -TODO Although could use a tutorial/ click through demo style instead
        */}

        {/* {(questionsData.questions.length < 1 && !mainContext.mainState.didSubmitYet) &&
          <img className="Demo-video" src="https://media.githubusercontent.com/media/RichardBekoe/assets/main/search-polygon/search_polygon_demo.gif" />

        } */}

        {/* SESSIONS links */}




        {(listSessions.length !== 0 &&
          questionsData.questions.length < 1 &&
          !mainContext.mainState.didSubmitYet) &&
          <h4 style={{ color: "silver" }} >
            Session Id:
            {listSessions}
          </h4>}

        {(questionsData.questions.length < 1 && mainContext.mainState.didSubmitYet) &&

          <h4 style={{ color: "silver" }} onClick={_refreshPage} >
            View Past Sessions
        <span style={{ color: "green", fontWeight: 900 }}> ⤻  </span> :
          </h4>
        }

        {/* <h3>{listSessions} </h3> */}


        {/* } */}

        {/* Separate Embedded Component */}
        <QuestionList questions={questionsData.questions} />

        {questionsData.questions.length > 0 &&
          <button style={{ color: "#001aff", fontWeight: 600 }}
            onClick={_handleSubmit} disabled={loadingState.submissionInProgress}>{loadingState.submissionInProgress ? "Loading..." : "Submit Questions"}</button>
        }

        <hr style={{ marginBottom: 50, marginTop: 50 }} />

        {/* INPUT search keyword box conditional configuration */}

        <input
          id="Text-input-keywordsearch" type="text"

          // styling related to placeholder colour
          className={mainContext.mainState.didSubmitYet ? "Color-greeninput" : "Color-redinput"}

          //font size  so that it fits on mobile and computer devices
          placeholder={mainContext.mainState.didSubmitYet ? "Search key phrases below: [Enter ↩︎]" : "Search keyword: No questions asked"}

          onKeyDown={_handleKeyDownKeywordQuery} style={{
            fontSize: "12px",
            borderWidth: mainContext.mainState.didSubmitYet ? "5px" : "3px", marginBottom: 50, borderColor: mainContext.mainState.didSubmitYet ? "green" : "red",
            color: mainContext.mainState.didSubmitYet ? "4px" : "2px"
          }}
          readOnly={!mainContext.mainState.didSubmitYet}
          autoFocus={mainContext.mainState.didSubmitYet} />



        {mainContext.mainState.didSubmitYet &&
          <small>Key phrases: <span style={{ color: "red", fontWeight: 500 }}> {keyphrasesWithLinksData.join(", ")}</span>
          </small>}

        {mainContext.mainState.didSubmitYet &&
          <small>Related key phrases: <span style={{ color: "red", fontWeight: 500 }}> {mainContext.mainState.relatedKeyPhrases.join(", ")} </span>
          </small>}

        {mainContext.mainState.didSubmitYet &&
          <div id="heart" className="item">
            <a href="https://richardbekoe.typeform.com/to/R3dolCA1" target="_blank" >
              <img className="bottom" src={heart} width="100px" />
            </a>
            <p className="caption"> Suggestions? </p>
          </div>
        }



      </header>

    </div >
  )

}

export default QuestionSubmissionPage;
